var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('section',{staticClass:"content-section wide"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-12 col-sm-7 text-left"},[_c('ul',{staticClass:"footer-nav"},[_c('li',[_c('router-link',{attrs:{"to":'/impressum'}},[_vm._v(" Impressum ")])],1),_c('li',[_c('router-link',{attrs:{"to":'/datenschutz'}},[_vm._v(" Datenschutz ")])],1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row mb-4 mt-2"},[_c('div',{staticClass:"col-12 col-sm-7 offset-md-2 text-left",staticStyle:{"font-size":"10px","line-height":"14px"}},[_vm._v(" Diese Informationen sind für medizinsche Fachkreise und Patienten aus Deutschland bestimmt. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12 col-sm-2 text-left"},[_c('img',{attrs:{"src":require("@/assets/az-logo-inv.svg")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://www.astrazeneca.de/nutzungsbedingungen.html"}},[_vm._v(" Nutzungsbedingungen ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"CookieReportsLink",attrs:{"href":"https://policy.cookiereports.com/b651bab6-en-gb.html","rel":"noopener nofollow noreferer","target":"_blank"}},[_vm._v("Cookie-Regelung")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://www.astrazeneca.de/kontakt.html"}},[_vm._v(" Kontakt ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"d-inline-block d-sm-none"},[_c('a',{attrs:{"href":"https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/de/de/amp-form.html","rel":"noopener nofollow noreferer","target":"_blank"}},[_vm._v(" Nebenwirkungen melden ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-3 text-right d-none d-sm-block"},[_c('ul',{staticClass:"footer-nav"},[_c('li',[_c('a',{attrs:{"href":"https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/de/de/amp-form.html","rel":"noopener nofollow noreferer","target":"_blank"}},[_vm._v(" Nebenwirkungen melden ")])])])])
}]

export { render, staticRenderFns }